import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionsService } from '../app-services/permissions.service';

export const rootRoutingGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
    const router = inject(Router);
    const permService = inject(PermissionsService);

    // return promise to make sure permissions are loaded before checking access rights
    return new Promise((resolve) => {
        permService.awaitPermissions().then((permissions) => {
            // check permissions of user
            const canAccessOverview = permService.hasPermission('routes.dashboard');
            const canAccessUsers = permService.hasPermission('routes.admin.manageUsers');
            // set redirect path - overview for operators and guests, admin-users for only userAdmins
            const redirectPath = canAccessOverview 
                ? router.createUrlTree(['overview']) 
                : canAccessUsers 
                    ? router.createUrlTree(['admin-users'])
                    : router.createUrlTree(['404']);

            // forward to selected page
            resolve(redirectPath);
        })
    })
};
