import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, EventEmitter, Inject, Input, model, OnDestroy, Output, Signal, TemplateRef, viewChild, ViewChild, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, formatDate } from '@angular/common';
import { StationColumn, overviewRepository } from 'src/app/core/stores/overview.repository';
import { ChargingStation } from '../../core/data-backend/models/charging-station';
import { Connector } from 'src/app/core/data-backend/models';
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, combineLatest, debounceTime, delay, EMPTY, filter, map, Observable, of, pairwise, ReplaySubject, share, startWith, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ResizedEvent } from 'src/app/core/directives/resized.directive';
import { TableColumnHeader } from '../../shared/table-header/table-header.component';
import { ChargerKeyName } from 'src/app/core/pipes/charger-key-name.pipe';
import { ColumnService } from 'src/app/core/helpers/column.service';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { TooltipConfig } from 'src/app/shared/table/table.component';
import { Filter, stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { ExtendedChargingStation } from 'src/app/core/helpers/transform-stations.helper';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BulkSelectAction } from 'src/app/shared/table-bulk-panel/table-bulk-panel.component';
import { OverviewService } from 'src/app/core/data-backend/data-services';
import { NotificationService } from 'src/app/core/app-services';
import { FormControl, FormGroup } from '@angular/forms';
import { addDays, differenceInDays } from 'date-fns';

export type FetchState = 'loading' | 'error' | 'empty' | 'success';

@Component({
    selector: 'app-table-overview',
    template: `
        <div 
            *ngIf="(requestState$ | async)?.state !== 'error'"
            class="sync-scroll-x"
            (scroll)="syncScroll(scrollAbove, 'x')"
            #scrollAbove
        >
            <div [style.width]="(tableWidth$ | async) + 'px'"></div>
        </div>
        <div
            class="table-parent"
            (window:resize)="this.updateTableWrapperRect()"
        >
            <div 
                class="table-wrapper"
                #tableWrapper
                (scroll)="syncScroll(tableWrapper, 'both')"
                [class.unset-scroll]="this.isDragging"
            >
                <table
                    (resized)="onTableResize($event)"
                    #tableRef
                >
                    <tbody class="sticky-header">
                        <tr 
                            *ngIf="(requestState$ | async)?.state !== 'error'"
                            class="table-head"
                            cdkDropList
                            cdkDropListOrientation="horizontal"
                            cdkDropListLockAxis="x"
                            (cdkDropListDropped)="dropTableHeaderEvents$.next($event)"
                        >
                            <th class="sticky-prev top-layer"></th>
                            <ng-container *ngFor="let header of columnsHeadersVM$ | async; trackBy: trackByFn; let i = index">
                                @if (this.permService.hasPermission('dashboard.table.columnReorder')) {
                                <th 
                                    scope="col"
                                    [class.is-dragging]="this.isDragging"
                                    [class.has-min-width]="!header.column.config?.noMinWidth"
                                    resizableColumn
                                    (columnWidth)="this.repo.setColumnWidth(header.column.id, $event)"
                                    [style.min-width.px]="this.getColumnWidth(this.repo.columnWidths$ | async, header.column.id)"
                                    (cdkDragStarted)="this.isDragging = true"
                                    (cdkDragMoved)="horizontalScroll($event)"
                                    [cdkDragStartDelay]="40"
                                    cdkDrag
                                >
                                    <div 
                                        class="cdk-drop-container"
                                        cdkDragHandle
                                    >
                                        <table-header
                                            [columnHeader]="header.columnHeader"
                                            [sortByKey]="repo.sortBy$ | async"
                                            [sortOrder]="repo.sortDirection$ | async"
                                            [isDragging]="isDragging"
                                            [columnSearch]="header.columnSearch"
                                            [tableWrapper]="tableWrapper"
                                            [filtersLoading]="filtersRepo.combineableFiltersPolling$ | async"
                                            [filters]="header.filters"
                                            [noSearch]="header.column.config?.noSearch ?? false"
                                            (onSort)="updateSortBy($event)"
                                            (onColumnSearch)="searchInColumn$.next({columnId: header.column.id, search: $event})"
                                            (onFilterSelection)="handleFilterInStore($event)"
                                            (onFilterReset)="deleteFilter($event)"
                                        >
                                        </table-header>
                                    </div>
                                </th>
                                } @else {
                                <th 
                                    scope="col"
                                    [class.has-min-width]="!header.column.config?.noMinWidth"
                                    resizableColumn
                                    (columnWidth)="this.repo.setColumnWidth(header.column.id, $event)"
                                    [style.min-width.px]="this.getColumnWidth(this.repo.columnWidths$ | async, header.column.id)"
                                >
                                    <table-header
                                        [columnHeader]="header.columnHeader"
                                        [sortByKey]="repo.sortBy$ | async"
                                        [sortOrder]="repo.sortDirection$ | async"
                                        [columnSearch]="header.columnSearch"
                                        [tableWrapper]="tableWrapper"
                                        [filtersLoading]="filtersRepo.combineableFiltersPolling$ | async"
                                        [filters]="header.filters"
                                        [noSearch]="header.column.config?.noSearch ?? false"
                                        (onSort)="updateSortBy($event)"
                                        (onColumnSearch)="searchInColumn$.next({columnId: header.column.id, search: $event})"
                                        (onFilterSelection)="handleFilterInStore($event)"
                                        (onFilterReset)="deleteFilter($event)"
                                    ></table-header>
                                </th>
                                }
                            </ng-container>
                            <th class="sticky-prev top-layer"></th>
                        </tr>
                    </tbody>
                    <!-- UI States -->
                    @if ((requestState$ | async)?.state !== 'success') {
                        @if (requestState$ | async; as state) {
                            @if (state.state == 'loading') {
                                <div class="p-32 mt-32 loading-curtain">
                                    <app-preloader type="squares"></app-preloader>
                                </div>
                            } @else {
                                <div class="p-32 mt-32 no-data">
                                    <div class="flex-row text-center align-items-center justify-content-center">
                                    <div class="material-icon default-icon mr-16">warning</div>
                                        <div class="text-left">
                                            <span *ngIf="state.state === 'error'">
                                                <p class="copy">{{ 'COMMON.ERROR.ONE' | translate }}</p>
                                            </span>
                                            <p *ngIf="state.message" class="subheadline">{{ state.message }}</p>
                                        </div>
                                    </div>
                                </div> 
                            }
                        }
                    }
                    @if ((requestState$ | async)?.state !== 'error') {
                        <ng-container *ngFor="let listItem of tableContents">
                            <tbody 
                                [attr.data-condensed]="this.expandedRows.indexOf(listItem.stationId) == -1" 
                                [attr.data-id]="listItem.stationId"
                                [class.is-dragging]="this.isDragging"
                            >
                                <tr
                                    class="cursor-pointer"
                                    [title]="'DASHBOARD.TABLE.GO_TO_DETAILS_PAGE' | translate"
                                    [class.highlight]="listItem.stationId === lastStationId"
                                    (click)="handleRouting(['/details', listItem.stationId])"
                                    [contextMenu]="permService.hasPermission('routes.stationDetails') ? [('COMMON.OPEN_IN_NEW_TAB' | translate)] : []"
                                    [contextScrollableParent]="tableWrapper"
                                    (onContextOptionSelect)="handleContextMenu($event, listItem.stationId)"
                                >
                                    <td class="sticky-prev ba-checkbox-td">
                                        <div *evcHasPermissions="'dashboard.table.bulkActions'">
                                            <input 
                                                type="checkbox" 
                                                name="bulk-actions"
                                                [title]="'DASHBOARD.BULK_ACTIONS.SELECT_FOR_BULK_ACTION' | translate"
                                                [checked]="((repo.allbulkActionRowIDs$ | async) || []).indexOf(listItem.stationId) > -1 ? true : null"
                                                (change)="this.repo.toggleBulkActionRow(listItem)"
                                                (click)="stopEventPropagation($event)"
                                            >
                                        </div>
                                    </td>
                                    
                                    @for (column of tableColumns$ | async; track column.id) {
                                        @if (column.config?.tooltip) {
                                            <td 
                                                [tooltip]="column.config?.tooltip?.text || getTooltipText(listItem, column)"
                                                [toSide]="column.config?.tooltip?.toSide || 'top'"
                                                [size]="column.config?.tooltip?.size || 'large'"
                                                [textAlign]="column.config?.tooltip?.textAlign || 'center'"
                                                [width]="column.config?.tooltip?.width || undefined"
                                            >
                                                <div 
                                                    [innerHTML]="returnListItem(listItem, column)"
                                                ></div>
                                            </td>
                                        } @else {
                                            <td>
                                                <div 
                                                    [innerHTML]="returnListItem(listItem, column)"
                                                ></div>
                                            </td>
                                        }
                                    }
                                    
                                    <td class="btn-expand-td">
                                        <button 
                                            *ngIf="listItem.connectors && listItem.connectors.length > 1"
                                            (click)="this.repo.toggleExpandedRow(listItem.stationId); stopEventPropagation($event)"
                                            [title]="'DASHBOARD.TABLE.EXPAND_CONNECTOR' | translate"
                                        ></button>
                                    </td>
                                </tr>
                                <ng-container *ngIf="listItem.connectors.length > 1">
                                    <ng-container *ngFor="let connector of listItem.connectors">
                                        <tr 
                                            class="subrow"
                                            *ngIf="this.expandedRows.indexOf(listItem.stationId) > -1"
                                            [@inOutAnimation]
                                        >
                                            <td class="sticky-prev"></td>
                                            @for (column of tableColumns$ | async; track column.id) {
                                                @if (column.config?.tooltip) {
                                                    <td 
                                                        [tooltip]="column.config?.tooltip?.text || getTooltipText(listItem, column)"
                                                        [toSide]="column.config?.tooltip?.toSide || 'top'"
                                                        [size]="column.config?.tooltip?.size || 'large'"
                                                        [textAlign]="column.config?.tooltip?.textAlign || 'center'"
                                                        [width]="column.config?.tooltip?.width || undefined"
                                                    >
                                                        <div 
                                                            [innerHTML]="returnConnectorListItem(listItem, connector, column)"
                                                        ></div>
                                                    </td>
                                                } @else {
                                                    <td>
                                                        <div 
                                                            [innerHTML]="returnConnectorListItem(listItem, connector, column)"
                                                        ></div>
                                                    </td>
                                                }
                                            }
                                            <td class="expand-filler"></td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </ng-container>
                    }
                </table>
            </div>
            <div class="scrollbar-wrapper">
                <div 
                    *ngIf="(requestState$ | async)?.state !== 'error'"
                    class="sync-scroll-y"
                    (scroll)="syncScroll(scrollBeside, 'y')"
                    #scrollBeside
                >
                    <div [style.height]="(tableHeight$ | async) + 'px'"></div>
                </div>
            </div>
            <evc-table-bulk-panel
                *evcHasPermissions="'dashboard.table.bulkActions'"
                [selectedTextTemplate]="bulkSelectTextTemplate"
                [options]="bulkSelectActions()"
                [selectedIds]="repo.allbulkActionRowIDs$ | async"
                (selectedIdsChange)="repo.emptyAllBulkActionRows()"
                (onAction)="handleBulkAction($event)"
            />
        </div>
        <div 
            *ngIf="(requestState$ | async)?.state !== 'error'"
            class="sync-scroll-x"
            (scroll)="syncScroll(scrollBelow, 'x')"
            #scrollBelow
        >
            <div [style.width]="(tableWidth$ | async) + 'px'"></div>
        </div>
        <!-- Pagination -->
        <div 
            *ngIf="this.currentPage && this.maxPage > 1"
            class="flex-row align-items-center justify-content-center pb-16 pt-16"
        >
            <button 
                class="chevron-overview-button chevron-left" 
                (click)="previousPage()"
                [class.disabled]="this.currentPage === 1"
            ></button>
            <input 
                #inputRef
                type="number"
                min="1"
                [max]="this.maxPage"
                [value]="this.currentPage"
                (input)="goToPage($event)"
                class="page-num-input"
            >
            <p class="copy pl-16"> {{ 'DASHBOARD.TABLE.OF_PAGES' | translate }} {{ this.maxPage }}</p>
            <button 
                class="chevron-overview-button chevron-right" 
                (click)="nextPage()"
                [class.disabled]="this.currentPage === this.maxPage"
            ></button>
        </div>

        <ng-template #hideStationsTemplate>
            <div class="hide-template">
                <form 
                    name="hideChargersForm"
                    [formGroup]="hideChargersForm"
                    (submit)="toggleStationsVisibility()"
                >
                    @if (repo.showOnlyHidden$ | async; as showOnlyHidden) {
                        <h3>{{ 'DASHBOARD.BULK_ACTIONS.SHOW.TITLE' | translate }}</h3>
                        <p>{{ 'DASHBOARD.BULK_ACTIONS.SHOW.TEXT' | translate }}</p> <br>
                    } @else {
                        <h3>{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.TITLE' | translate }}</h3>
                        <div class="text">
                            <span>{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.BEFORE_INPUT' | translate }}</span>
                            <input 
                                min="1"
                                type="number" 
                                formControlName="duration"
                            >
                            <span>{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.AFTER_INPUT' | translate }}</span>
                        </div>
                        <evc-date-input
                            [size]="'small'"
                            [showTime]="false"
                            [min]="tomorrow"
                            [value]="hideChargersForm.get('untilDate')?.value ?? null"
                            (valueChange)="hideChargersForm.patchValue({untilDate: $event})"
                        />
                    }
                    <button type="submit">{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.BUTTON' | translate }}</button>
                </form>
            </div>
        </ng-template>
    `,
    styleUrls: ['./table-overview.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-10px)' }),
                animate('.25s ease-out', 
                style({ opacity: 1, transform: 'translateY(0px)' }))
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0px)' }),
                animate('.25s ease-in', 
                style({ opacity: 0, transform: 'translateY(-10px)' }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class TableOverviewComponent implements AfterViewInit, OnDestroy {
    @ViewChild('inputRef') inputRef!: ElementRef;
    // elRefs for wrapper and scroll elements
    @ViewChild('tableWrapper') tableWrapper: ElementRef | undefined = undefined;
    @ViewChild('scrollAbove') scrollAbove: ElementRef | undefined = undefined;
    @ViewChild('scrollBelow') scrollBelow: ElementRef | undefined = undefined;
    @ViewChild('scrollBeside') scrollBeside: ElementRef | undefined = undefined;
    @ViewChild('tableRef') tableRef: ElementRef | undefined = undefined;
    tableWrapperRect: {wrapperStart: number, wrapperEnd: number} | undefined;
    // keep track of table positioning for external scroll containers
    tableWidth$: Subject<number> = new BehaviorSubject<number>(0);
    tableHeight$: Subject<number> = new BehaviorSubject<number>(0);
    // table data
    private _tableRows: ExtendedChargingStation[] = []

    // setter for table data
    @Input('tableContents') set tableContents(contents: ExtendedChargingStation[] | null) {
        if (!contents) {
            this._tableRows = [];
        } else if (!this.rowsAreEqual(this._tableRows, contents)) {
            this._tableRows = contents;
        }
    };
    get tableContents() {
        return this._tableRows
    }
    // main columns of table
    public tableColumns$ = new BehaviorSubject<StationColumn[]>([]);
    @Input() set tableColumns(columns: StationColumn[] | null) {
        this.tableColumns$.next(columns ?? [])
    }
    // columns with additional attr, e.g. filters, sort, search etc.
    public columnsHeadersVM$: Observable<{
        columnHeader: TableColumnHeader,
        column: StationColumn,
        columnSearch: string | null,
        filters: Filter[] | null
    }[]>;

    @Input('expandedRows') expandedRows!: any;
    // Pagination
    @Input('pagination') currentPage: number | null = 1;
    @Input('maxPage') maxPage!: number;
    // id of last viewed station
    @Input('lastStationId') lastStationId: ChargingStation['stationId'] | null = null;
    @Output('columnSort') columnSort: EventEmitter<number[]> = new EventEmitter<number[]>()
    @Output('fullscreen') fullscreen: EventEmitter<boolean> = new EventEmitter<boolean>()
    isDragging: boolean = false;
    // all states for categorical string values (best -> worst)
    readonly possibleStates = ['Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];
    // timer ref for Firefox scrollbar fix
    private _FFTimer: any;
    // state of getChargingStations request
    requestState$: Observable<{state: FetchState, message?: string}>;
    // column key and search value of column search
    public searchInColumn$ = new BehaviorSubject<{columnId: StationColumn['id'], search: string | null} | null>(null);
    // causes reordering of table columns
    public dropTableHeaderEvents$ = new BehaviorSubject<CdkDragDrop<string[]> | null>(null);
    // store last scroll positions
    private lastScrollLeft: number = 0;
    private lastScrollTop: number = 0;

    // TemplateRef for reset bulk action
    public hideStationsTemplate = viewChild<TemplateRef<any>>('hideStationsTemplate');
    public tomorrow = addDays(new Date(), 1);
    // form to handle and sync inputs
    public hideChargersForm = new FormGroup({
        duration: new FormControl<number>(7),
        untilDate: new FormControl<Date>(addDays(new Date(), 7))
    });

    public bulkSelectActions: Signal<BulkSelectAction[]>;
    public bulkSelectTextTemplate = (selectedIds: (string | number)[]) => {
        const text = selectedIds.length == 1 
            ? this._t('DASHBOARD.BULK_ACTIONS.STATION_SELECTED.ONE', {n: selectedIds.length})
            : this._t('DASHBOARD.BULK_ACTIONS.STATION_SELECTED.OTHER', {n: selectedIds.length});
        return text
    };
    
    constructor(
        public repo: overviewRepository,
        public filtersRepo: stationFiltersRepository,
        public permService: PermissionsService,
        private _overviewService: OverviewService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _elRef: ElementRef,
        private _columnService: ColumnService,
        private _chargerKeyNamePipe: ChargerKeyName,
        private _translate: TranslateService,
        @Inject(DOCUMENT) private _document: Document
    ) {
        // update bulk select actions with new language
        const newLang = toSignal(this._translate.onLangChange);
        this.bulkSelectActions = computed(() => {
            const lang = newLang();
            const hideStationsTemplate = this.hideStationsTemplate();
            if (!hideStationsTemplate) return [];
            return [
                {
                    id: 'select-more',
                    title: this._t('DASHBOARD.BULK_ACTIONS.SELECT_MORE.TITLE'),
                    icon: 'add',
                    actions: [
                        {
                            title: this._t('DASHBOARD.BULK_ACTIONS.SELECT_MORE.ON_CURRENT_PAGE'),
                            id: 'select-current-page'
                        }
                    ]
                },
                {
                    id: 'restart-stations',
                    title: this._t('DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.TITLE'),
                    icon: 'sync',
                    confirmation: {
                        title: this._t('DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.CONFIRMATION.TITLE'),
                        text: (selectedIds) => this._t('DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.CONFIRMATION.TEXT', {n: selectedIds.length, stationVar: selectedIds.length == 1 ? this._t('COMMON.STATION.ONE') : this._t('COMMON.STATION.OTHER')}),
                        button: {
                            text: this._t('DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.CONFIRMATION.BUTTON'),
                            icon: 'sync'
                        }
                    },
                    conditions: {
                        disabled: (selectedIds) => selectedIds.length > 30
                    }
                },
                {
                    id: 'hide-stations',
                    title: this._t('DASHBOARD.BULK_ACTIONS.HIDE.TITLE'),
                    icon: 'visibility_off',
                    template: hideStationsTemplate
                },
                {
                    id: 'open-in-new-tab',
                    title: this._t('DASHBOARD.BULK_ACTIONS.OPEN_IN_NEW_TAB'),
                    icon: 'open_in_new',
                    conditions: {
                        show: (selectedIds) => this.permService.hasPermission('routes.stationDetails'),
                        disabled: (selectedIds) => selectedIds.length > 1
                    }
                }
            ]
        });

        this.searchInColumn$.pipe(
            takeUntilDestroyed(),
            // prevent firing on each keystroke
            debounceTime(400),
            withLatestFrom(this.tableColumns$),
            tap(([columnSearch, tableColumns]) => {
                if (columnSearch == null ) return;
                this._saveScrollPosition()

                // find column by id
                const column = tableColumns.find((column) => column.id === columnSearch.columnId);
                if (!column) return;

                // get first searchable key
                const allKeys: string[] = (column.keyInArray as string[] || []).concat(column.keyInConnector as string[] || []);
                const searchableKey = allKeys.find((key) => searchableKeys.includes(key as (keyof ChargingStation | keyof Connector)));
            
                if (searchableKey && columnSearch.search) {
                    this.repo.updateSearchColumn(columnSearch.columnId, columnSearch.search)
                } else {
                    this.repo.deleteSearchColumn(columnSearch.columnId)
                }
            })
        ).subscribe()
        
        // applies latest scroll position to table with new data
        this.repo.stations$.pipe(
            takeUntilDestroyed(),
            startWith(null),
            pairwise(),
            map(([prev, curr]) => (!prev || prev && prev.isLoading) && curr && !curr.isLoading && curr.data.length > 0), // only fire if not loading and data is available
            debounceTime(100), // add a slight delay to allow the table to fully render before applying scroll pos
            filter((shouldApply) => shouldApply === true),
            tap(() => this._applyScrollPosition(false))
        ).subscribe()

        this.requestState$ = this.repo.stations$.pipe(
            withLatestFrom(this.repo.searchQuery$),
            withLatestFrom(this.filtersRepo.activeFilterValues$),
            map(([[state, searchQuery], activeFilters]) => {
                // only show loading if not currently autoUpdating
                if (state.isLoading && !state.isAutoUpdate) return { state: 'loading' as FetchState }
                if (state.isError || (state.isSuccess && state.data.length === 0)) {
                    let out = {
                        state: (state.isError ? 'error' : 'empty') as FetchState,
                        message: ''
                    }

                    if (searchQuery) {
                        if (activeFilters.length > 0) {
                            out.message = this._translate.instant('DASHBOARD.TABLE.NO_DATA_MATCHING_SEARCH_FILTERS', {content: searchQuery})
                        } else {
                            out.message = this._translate.instant('DASHBOARD.TABLE.NO_DATA_MATCHING_SEARCH', {content: searchQuery})
                        }
                    } else {
                        out.message = this._translate.instant('DASHBOARD.TABLE.NO_DATA_FOUND')
                    }

                    return out
                }
                return { state: 'success' as FetchState }
            }),
            share({connector: () => new ReplaySubject(1)})
        )

        // listens to dropping table header, reorders columns Subject
        this.dropTableHeaderEvents$.pipe(
            takeUntilDestroyed(),
            withLatestFrom(this.tableColumns$),
            tap(([event, columns]) => {
                this.isDragging = false
                window.clearTimeout(this.horizontalScrollTimeOut)
                if (!columns || !event) return
                // reoder items
                moveItemInArray(columns, event.previousIndex, event.currentIndex);
                // emit new value, use spread operator to assign "new" value
                this.tableColumns$.next([...columns])
                // emit ordered ids
                this.columnSort.emit(columns.map(column => column.id))
            })
        ).subscribe()

        // all currently searchable keys
        const searchableKeys: (keyof ChargingStation | keyof Connector)[] = [
            'chargerModel', 'chargerVendor', 'socketType', 'currentType', 'address', 'postalCode', 'city', 'countryCode', 'chargerVendor', 
            'lastError', 'ocppVersion', 'tenant', 'evseId', 'customEvseId', 'evseName', 'stationId', 'dataConnectionType', 'routerType', 'ticketName',
            'locationDescription', 'serialNumber', 'cpoName', 'cpoName', 'cpoSellerId'
        ];

        // combine all values for table header interactions
        this.columnsHeadersVM$ = combineLatest([
            this.tableColumns$,
            this.filtersRepo.mappedActiveFilters$,
            this.filtersRepo.baseFilters$,
            this.repo.searchColumns$
        ]).pipe(
            debounceTime(2),
            tap(([columns, activeFilters, allFilters, searchColumns]) => {
                // check if searchColumns are set on a non-selected column
                const searchingInColumns = searchColumns.map((search) => search.id);
                const selectedColumnIds = columns.map((col) => col.id);
                const mismatch = searchingInColumns.filter((searchId) => !selectedColumnIds.includes(searchId));
                // delete searchColumn of any column that's not currently selected
                if (mismatch) {
                    mismatch.forEach(this.repo.deleteSearchColumn);
                }
            }),
            map(([columns, activeFilters, allFilters, searchColumns]) => {
                return columns.map((column) => {
                    let allKeys: string[] = (column.keyInArray as string[] || []).concat(column.keyInConnector as string[] || []),
                        mappedKeys = allKeys.map((key) => {
                            return {
                                key: key,
                                title: this._chargerKeyNamePipe.transform(key)
                            }
                        });

                    // find all matching filters of provided keys
                    let matchingFilters: Filter[] = [];
                    for (let i = 0; i < allKeys.length; i++) {
                        const key = allKeys[i];
                        const matchingActiveFilter = activeFilters.find((filter) => filter.id === key);
                        if (matchingActiveFilter) {
                            matchingFilters.push(matchingActiveFilter)
                            continue
                        }
                        const matchingFilter = allFilters?.find((filter) => filter.id === key);
                        if (matchingFilter) {
                            let filterWithEmptyValue: any = matchingFilter;
                            filterWithEmptyValue['value'] = this.filtersRepo.getEmptyFilterValue(matchingFilter);
                            matchingFilters.push(filterWithEmptyValue)
                        }
                    }

                    // check if current column has active search term
                    let columnSearch = searchColumns.find((searchCol) => searchCol.id === column.id);

                    return {
                        columnHeader: {
                            title: column.name,
                            keys: mappedKeys,
                            canBeSearched: allKeys.some((key) => searchableKeys.includes(key as (keyof ChargingStation | keyof Connector)))
                        },
                        column: column,
                        filters: matchingFilters,
                        columnSearch: columnSearch?.value ?? null
                    }
                })
            })
        )

        combineLatest([
            this.repo.fullWidth$,
            this.repo.selectedColumns$
        ]).pipe(
            takeUntilDestroyed(),
            debounceTime(100),
            delay(100),
            tap(([fullWidth, _]) => {
                if (this.tableWrapper && this.tableRef) {
                    this.fullscreen.emit(
                        // fullscreen button active
                        fullWidth &&
                        // table overflows visible area
                        this.tableRef.nativeElement.offsetWidth > 
                        this.tableWrapper.nativeElement.offsetWidth
                    );
                }
            })
        ).subscribe();

        // handles updates in hide stations template, syncs interval input with date
        const durationControl = this.hideChargersForm.get('duration');
        const untilDateControl = this.hideChargersForm.get('untilDate');

        if (durationControl && untilDateControl) {
            durationControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((duration: number | null) => {
                const newUntilDate = addDays(new Date(), duration ?? 0);
                untilDateControl.setValue(newUntilDate, { emitEvent: false });
            });

            untilDateControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((untilDate: Date | null) => {
                const newDuration = differenceInDays(new Date(untilDate!), new Date()) + 1;
                durationControl.setValue(newDuration, { emitEvent: false });
            });
        }
    }

    public handleBulkAction(event: {action: string, selectedIds: (string | number)[]}) {
        switch (event.action) {
            case 'select-current-page':
                // add all non-selected stations from current page to selection
                let stations = this._tableRows.filter((station) => event.selectedIds.indexOf(station.stationId) == -1);
                this.repo.updateBulkActionRows(stations);
                break;
            case 'restart-stations':
                this._overviewService.resetChargingStations({
                    body: {
                        stationIds: event.selectedIds as string[]
                    }
                }).pipe(take(1)).subscribe({
                    next: (v) => {
                        let regex = /success: (\d+), error: (\d+)/;
                        let match = v.detail.match(regex);
                        if (match) {
                            let [amtSuccess, amtError] = match.slice(1).map(Number);
                            if (amtSuccess > 0) {
                                const text = this._t(
                                    amtSuccess == 1
                                        ? 'DASHBOARD.BULK_ACTIONS.RESET_SUCCESS.ONE'
                                        : 'DASHBOARD.BULK_ACTIONS.RESET_SUCCESS.OTHER',
                                    { n: amtSuccess }
                                );
                                this._notificationService.showSuccess(text);
                            }
                            if (amtError > 0) {
                                const text = this._t(
                                    amtError == 1
                                        ? 'DASHBOARD.BULK_ACTIONS.RESET_ERROR.ONE'
                                        : 'DASHBOARD.BULK_ACTIONS.RESET_ERROR.OTHER',
                                    { n: amtError }
                                );

                                this._notificationService.showError(text);
                            }
                        }
                    },
                    error: (v) => {
                        const text = this._t(
                            event.selectedIds.length == 1
                                ? 'DASHBOARD.BULK_ACTIONS.RESET_ERROR.ONE'
                                : 'DASHBOARD.BULK_ACTIONS.RESET_ERROR.OTHER',
                            { n: event.selectedIds.length }
                        );

                        this._notificationService.showError(text);
                    }
                });
                break;
            case 'open-in-new-tab':
                const stationId = event.selectedIds[0];
                const url = this._router.serializeUrl(
                    this._router.createUrlTree([`/details/${stationId}`])
                );
                window.open(url, '_blank')  
                break;
        }
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    public toggleStationsVisibility() {
        combineLatest({
            stationIds: this.repo.allbulkActionRowIDs$,
            showOnlyHidden: this.repo.showOnlyHidden$
        }).pipe(
            take(1),
            switchMap(({stationIds, showOnlyHidden}) => {
                const interval = this.hideChargersForm.get('duration')?.value;
                if (interval == null) return EMPTY;

                return this._overviewService.changeVisibilityOfChargingStations({
                    visibility: showOnlyHidden ? 'show' : 'hide',
                    stationIds,
                    interval
                }).pipe(
                    map((response) => ({response, showOnlyHidden, interval, stationIds}))
                )
            }),
            take(1)
        ).subscribe({
            error: (res) => {
                this._notificationService.showLocalizedError('DASHBOARD.BULK_ACTIONS.HIDE_ERROR');
            },
            next: ({response, showOnlyHidden, interval, stationIds}) => {
                // remove selection to close bulk actions
                this.repo.emptyAllBulkActionRows();
                const stationText = stationIds.length == 1 ? this._t('COMMON.STATION.ONE') : this._t('COMMON.STATION.OTHER');
                if (showOnlyHidden) {
                    const text = this._t('DASHBOARD.BULK_ACTIONS.SHOW_SUCCESS', {n: stationIds.length, stationVar: stationText});
                    this._notificationService.showSuccess(text);
                } else {
                    const interpolateParams = {
                        n: stationIds.length,
                        dayVar: interval == 1 ? this._t('COMMON.TIMES.DAY.ONE') : this._t('COMMON.TIMES.DAY.OTHER')
                    }
                    const text = stationIds.length == 1 
                        ? this._t('DASHBOARD.BULK_ACTIONS.HIDE_SUCCESS.ONE', interpolateParams) 
                        : this._t('DASHBOARD.BULK_ACTIONS.HIDE_SUCCESS.OTHER', interpolateParams);
                    this._notificationService.showSuccess(text);
                }
                this.repo.incrementRefetchRev();
            }
        })
        
    }

    ngAfterViewInit(): void {
        this.updateTableWrapperRect()

        // set inital width (+ 10 for checkbox offset and margins) and height
        this.tableWidth$.next(this.tableRef?.nativeElement.offsetWidth + 10)
        this.tableHeight$.next(this.tableRef?.nativeElement.offsetHeight)

        // keep scrollbars visible on firefox by constantly updating position
        if (window.navigator.userAgent.includes('Firefox')) {
            const scrollKeeperFF = () => {
                this._FFTimer = setTimeout(() => {
                    if (this.scrollAbove && this.scrollBeside && this.scrollBelow) {
                        const updatableScrollEls = [this.scrollAbove.nativeElement, this.scrollBeside.nativeElement, this.scrollBelow.nativeElement];
                        updatableScrollEls.forEach((el) => {
                            const scrollDirection = el.classList.contains('sync-scroll-x') ? 'scrollLeft' : 'scrollTop';
                            el[scrollDirection] = el[scrollDirection] + 1 // triggers visibility of scrollbar
                            el[scrollDirection] = el[scrollDirection] - 1 // instantly return to initial position
                        });
                    }
                    scrollKeeperFF()
                }, 455)
            }
            scrollKeeperFF()
        }
    }

    // trackBy fn to help keeping focus on colHeader in ngFor
    public trackByFn(colIndex: any) {
        return colIndex;
    }

    // updates start and end of wrapping container for further calculations
    public updateTableWrapperRect() {
        if (!this.tableWrapper) return;
        let rect: DOMRect = this.tableWrapper.nativeElement.getBoundingClientRect(),
            tolerance = 80;

        this.tableWrapperRect = {
            wrapperStart: rect.x + tolerance,
            wrapperEnd: rect.x + rect.width - tolerance
        }
    }

    getColumnWidth(allCols: { id: StationColumn['id'], width: number }[] | null, id: StationColumn['id']): number | null {
        if (allCols === null) return null
        return allCols.find(col => col.id === id)?.width || null
    }

    private _saveScrollPosition() {
        if (!this.tableWrapper) return

        let offsetTop = this.tableWrapper.nativeElement.scrollTop,
            offsetLeft = this.tableWrapper.nativeElement.scrollLeft;

        this.repo.setOffsetTop(offsetTop)
        this.repo.setOffsetLeft(offsetLeft)
    }

    private _applyScrollPosition(scrollAppWindow: boolean = true) {
        let offset: { top?: number | null, left?: number | null } = this.repo.getScrollOffset();
        this.tableWrapper?.nativeElement.scrollTo({left: offset.left, top: offset.top})
        // scroll additional bars above and below
        
        this.scrollAbove?.nativeElement.scrollTo({left: offset.left })
        this.scrollBelow?.nativeElement.scrollTo({left: offset.left })

        if (scrollAppWindow && ((offset.top && offset.top > 0) || (offset.left && offset.left > 0))) {
            // if offset is stored, scroll window to upper edge of component, 60px padding for nav and scrollbar
            let elPos = this._elRef.nativeElement.getBoundingClientRect().y - 60;
            // prevent unintended scrolling back up
            if (elPos < 100) return
            window.scrollTo({top: this._elRef.nativeElement.getBoundingClientRect().y - 60})
        }
    }

    // scrolls table to left or right if hovering over wrapper boundaries
    // gets called while dragging table header
    horizontalScrollTimeOut: any;
    horizontalScroll(event: any) {
        if (!this.tableWrapperRect || !this.tableWrapper) return
        let dragPosition = event.pointerPosition.x;
        window.clearTimeout(this.horizontalScrollTimeOut)

        // scroll table left/right if pointerPosition is close to start/end of wrapper
        if (
            dragPosition < this.tableWrapperRect.wrapperStart &&
            this.tableWrapper.nativeElement.scrollLeft !== 0
        ) {
            this.tableWrapper.nativeElement.scrollLeft -= 3
        } else if (dragPosition > this.tableWrapperRect.wrapperEnd) {
            this.tableWrapper.nativeElement.scrollLeft += 3
        } else {
            return
        }

        this.horizontalScrollTimeOut = setTimeout(() => {
            this.horizontalScroll(event)
        }, 2);
    }

    public onTableResize(event: ResizedEvent) {
        // update height and width
        this.tableWidth$.next(event.newRect.width + 10)
        this.tableHeight$.next(event.newRect.height)

        if (event.isFirst) {
            this._applyScrollPosition()
        }
    }

    // syncs scroll position of external scroll bars and table
    public syncScroll(sourceEl: Element, axis: 'x' | 'y' | 'both', threshold: number = 5) {
        if (!this.scrollAbove || !this.tableWrapper || !this.scrollBeside || !this.scrollBelow) return;
    
        // get all synced elements, remove source
        const scrollEls = [this.scrollAbove.nativeElement, this.tableWrapper.nativeElement, this.scrollBeside.nativeElement, this.scrollBelow.nativeElement]
            .filter(el => el !== sourceEl);
    
        // only set requested axis, or both
        const wantedX = axis === 'x' || axis === 'both';
        const wantedY = axis === 'y' || axis === 'both';
        const newScrollLeft = wantedX ? sourceEl.scrollLeft : undefined;
        const newScrollTop = wantedY ? sourceEl.scrollTop : undefined;
    
        // check if the difference exceeds the threshold
        const scrollLeftChanged = newScrollLeft !== undefined && Math.abs(newScrollLeft - this.lastScrollLeft) >= threshold;
        const scrollTopChanged = newScrollTop !== undefined && Math.abs(newScrollTop - this.lastScrollTop) >= threshold;
    
        // update the last scroll positions
        if (scrollLeftChanged) {
            this.lastScrollLeft = newScrollLeft || 0;
        }
        if (scrollTopChanged) {
            this.lastScrollTop = newScrollTop || 0;
        }
    
        // update scroll positions for synced elements
        scrollEls.forEach(el => {
            if (scrollLeftChanged && el.scrollLeft !== newScrollLeft) {
                el.scrollLeft = newScrollLeft || 0;
            }
            if (scrollTopChanged && el.scrollTop !== newScrollTop) {
                el.scrollTop = newScrollTop || 0;
            }
        });
    }

    // Directly navigate to page
    public goToPage(event: Event) {
        // @ts-ignore
        if (!(<InputEvent>event).target.valueAsNumber) return;
        // @ts-ignore
        let pageInt = parseInt((<InputEvent>event).target?.valueAsNumber as string)
        pageInt = pageInt > this.maxPage ? this.maxPage : pageInt
        pageInt = pageInt < 1 ? 1 : pageInt
        // fix for non-num input 
        pageInt = isNaN(pageInt) ? 1 : pageInt

        this.inputRef.nativeElement.value = pageInt
        this.currentPage = pageInt
        this.repo.updatePagination(pageInt)
    }
    // navigate to previous page
    public previousPage() {
        if (this.currentPage !== null && this.currentPage > 1) {
            this.repo.updatePagination(this.currentPage - 1)
            // reset offsetTop
            this.repo.setOffsetTop(0)
        }
    }
    // navigate to next page
    public nextPage() {
        if (this.currentPage !== null && this.currentPage < this.maxPage) {
            this.repo.updatePagination(this.currentPage + 1)
            // reset offsetTop
            this.repo.setOffsetTop(0)
        }
    }

    // Updates selection and values of column filters
    public handleFilterInStore(filter: {id: string, value: any}) {
        this._saveScrollPosition();
        if (this.filtersRepo.hasFilter(filter.id)) {
            this.filtersRepo.updateFilterValue(filter.id, filter.value)
        } else {
            this.filtersRepo.addFilter(filter.id, filter.value)
        }
    }
    // delete active filter from repo on "reset"
    // the column filter will always be available, but only resetting previously set filters would keep
    // them as empty filters in the main "station-filters" component
    public deleteFilter(filter: Filter) {
        this._saveScrollPosition();
        this.filtersRepo.deleteFilters(filter.id)
    }

    // stop propagation of event (nested actions in routerlink of tablerow)
    public stopEventPropagation(event: Event) {
        event.stopPropagation();
    }

    // navigates to route if no text is selected
    // allowing users to select text on linked row
    public handleRouting(url: string[]) {
        if (this.permService.hasPermission('routes.stationDetails') && !this._document.getSelection()?.toString()) {
            const urlTree = this._router.createUrlTree(url);
            this._router.navigateByUrl(urlTree);
        }
    }

    // formats values in list, optionally adding suffix
    private formatValue(values: any, column: StationColumn) {
        // apply tooltip rules
        if (column.config?.tooltip) {

            // get the position of the value to modify
            let pos = null;
            let key = column.config?.tooltip.key;
            let keyPos = column.keyInArray?.indexOf(key as keyof ChargingStation);
            if (keyPos !== undefined && keyPos !== -1) pos = keyPos;

            let keyConnPos = column.keyInConnector?.indexOf(key as keyof Connector);
            if (keyConnPos !== undefined && keyConnPos !== -1) {
                pos = keyPos !== undefined ? keyPos + column.keyInArray!.length : keyConnPos;
            }

            // max-40 rule
            if (column.config.tooltip.type === 'max-40' && pos !== null && pos >= 0 && pos < values.length) {
                values[pos] = values[pos]?.length > 40 ? `${values[pos].substring(0, 40)}...` : values[pos];
            }
        }

        return column.renderCell ? column.renderCell(values) : this._columnService.getDefaultStyling(values);
    }

    // return value for table cell
    public returnListItem(station: ExtendedChargingStation, column: StationColumn) {
        if (column.keyInArray) {
            let values: any[] = column.keyInArray.map(key => station[key]);
            if (column.keyInConnector && station.featuredConnector) {
                values = values.concat(column.keyInConnector.map(key => station.featuredConnector?.[key]));
            }
            return this.formatValue(values, column);
        }
        if (!column.keyInConnector || !station.featuredConnector) return;
        return this.returnConnectorListItem(station, station.featuredConnector, column)
    }

    // returns values of stations connectors
    returnConnectorListItem(station: ExtendedChargingStation, connector: Connector, column: StationColumn) {
        if (!column.keyInConnector) return;
        let values: any[] = column.keyInConnector.map(key => connector[key]);
        if (column.keyInArray) {
            values.unshift(...column.keyInArray.map(key => station[key]));
        }
        return this.formatValue(values, column)
    }

    // updates sortBy, toggling asc/desc for sortOrder
    updateSortBy(sortInfo: { key: string, direction: 'asc' | 'desc' | null }) {
        this._saveScrollPosition()

        this.repo.updateSortBy(sortInfo.key, sortInfo.direction ?? undefined)

        // go back to page 1 & close all dropdowns
        this.repo.updatePagination(1)
    }

    public handleContextMenu(option: string, stationId: ChargingStation['stationId']) {
        // open station in new tab
        if (stationId && this.permService.hasPermission('routes.stationDetails')) {
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`/details/${stationId}`])
            );

            window.open(url, '_blank')
        }
    }

    // get optional tooltip text
    getTooltipText(station: ExtendedChargingStation, column: StationColumn) {
        let tooltipConfig = column.config?.tooltip
            ? JSON.parse(JSON.stringify(column.config?.tooltip)): undefined;

        if (tooltipConfig) {
            let key = tooltipConfig.key as keyof ExtendedChargingStation;
            let cellContent = key in station ? station[key]?.toString() : null;
            if (cellContent) {
                return this.getTooltipRuling(tooltipConfig, cellContent);
            } else {
                if (!station.featuredConnector) return null;
                return this.getConnectorTooltipText(station.featuredConnector, column)
            }
        }
        return null;
    }

    // tooltip for connector attr
    getConnectorTooltipText(connector: Connector, column: StationColumn) {
        let tooltipConfig = column.config?.tooltip
            ? JSON.parse(JSON.stringify(column.config?.tooltip)): undefined;

        if (tooltipConfig) {
            let key = tooltipConfig.key as keyof Connector;
            let cellContent = key in connector ? connector[key]?.toString() : null;
            if (cellContent) return this.getTooltipRuling(tooltipConfig, cellContent);
        }
        return null;
    }

    getTooltipRuling(tooltipConfig: TooltipConfig, cellContent: string) {
        if (!tooltipConfig.text) tooltipConfig.text = cellContent;
        // max-40 rule
        if (tooltipConfig.type === 'max-40') {
            tooltipConfig.text = cellContent.length > 40 ? cellContent : undefined;
        }
        return tooltipConfig.text ? tooltipConfig.text : null;
    }

    rowsAreEqual = (a: any, b: any): any => {
        if (a === b) return true;
        if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
        if (typeof a !== 'object' || typeof b !== 'object' || !a || !b) return a === b;
        if (Object.keys(a).length !== Object.keys(b).length) return false;
        return Object.keys(a).every(k => this.rowsAreEqual(a[k], b[k]));
    };

    onClose() {}
    onEnter() {}
    onOpen() {}

    ngOnDestroy(): void {
        this._saveScrollPosition()
        window.clearTimeout(this._FFTimer)
    }
}
