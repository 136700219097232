import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, catchError, combineLatest, debounceTime, distinctUntilChanged, filter, from, map, mergeMap, of, skip, startWith, switchMap, take, tap, toArray, withLatestFrom } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { OverviewCacheService } from 'src/app/core/app-services/overview-cache.service';
import { FiltersetsService, OverviewService } from 'src/app/core/data-backend/data-services';
import { Alert, FilterSet, SharedFilterSet } from 'src/app/core/data-backend/models';
import { ChargerKeyName } from 'src/app/core/pipes/charger-key-name.pipe';
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { selectFilterOption } from 'src/app/shared/station-filters/select-filters/select-filters.component';
import { TableColumn } from 'src/app/shared/table/table.component';
import { stationFiltersRepository } from '../core/stores/station-filters.repository';
import { decodeString } from '../core/helpers/utils.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ColumnService } from '../core/helpers/column.service';
import { appRepository } from '../core/stores/app.repository';
import { BulkSelectAction } from '../shared/table-bulk-panel/table-bulk-panel.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'evc-filtersets',
    template: `
        <evc-collapsible
            [parentClasses]="['details-header-collapsible']"
            [collapsed]="(collapsed$ | async) ?? true"
            (collapsedChange)="collapsed$.next($event)"
        >
            <ng-container header>
                <h1 class="title">{{ 'FILTERS.MANAGE.CREATE_FILTER_SET' | translate: {content: ('FILTERS.FILTER_SET.ONE' | translate)} }}</h1>
            </ng-container>

            <ng-container body>
                <div class="wrapper">
                    <evc-filterset-mask
                        [availableFilters]="allAvailableFilterKeys$ | async"
                        [baseFilters]="filtersRepo.baseFilters$ | async"
                        [currentFilterSets]="(filtersRepo.filterSets$ | async)?.data || []"
                        [type]="(typeTable$ | async)"
                        (onError)="collapsed$.next(true)"
                        (onSuccess)="collapsed$.next(true)"
                        (switchType)="typeTable$.next($event)"
                    >
                    </evc-filterset-mask>
                </div>
            </ng-container>
        </evc-collapsible>

        <div class="container pt-32">
            <div class="box-border--gradient no-bottom-padding">
                <div class="title-row flex-row align-items-center justify-content-between">
                    <h2 class="title">{{ 'FILTERS.MANAGE.MANAGE_FILTER_SETS' | translate: {content: ('FILTERS.FILTER_SET.OTHER' | translate)} }}</h2>

                    <div class="flex-row align-items-center justify-content-end">
                        <button
                            class="quick-filter mr-16"
                            [class.active]="alertOnly$ | async"
                            (click)="alertOnly$.next(!alertOnly$.getValue())"
                        >{{ 'FILTERS.ALERT_ONLY' | translate }}</button>
                        <button
                            class="quick-filter mr-32"
                            [class.active]="resultsOnly$ | async"
                            (click)="resultsOnly$.next(!resultsOnly$.getValue())"
                        >{{ 'FILTERS.RESULTS_ONLY' | translate }}</button>
                        <form class="search">
                            <input
                                [value]="search$ | async"
                                type="text"
                                [placeholder]="'COMMON.SEARCH' | translate"
                                (input)="handleSearch($event)"
                            >
                            <button
                                class="submit-search"
                                (click)="search$.next('')"
                            >
                                <span class="material-icon">
                                    @if(searchQuery$ | async) {clear}
                                    @else {search}
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="tabs">
                    <radio-button-group
                        class="rbgroup"
                        [options]="[
                            {
                                label: ('FILTERS.OWN_FILTER_SET.OTHER' | translate),
                                value: 'default'
                            },
                            {
                                label: ('FILTERS.SHARED_FILTER_SET.OTHER' | translate),
                                value: 'shared'
                            }
                        ]"
                        [style]="'line'"
                        [activeSelection]="(typeTable$ | async)"
                        (activeSelectionChange)="typeTable$.next($event == 'default' ? 'default' : 'shared')"
                    ></radio-button-group>
                </div>
            </div>
        </div>

        <div class="container pt-32 pb-32">
            <evc-table
                [tableId]="'manage-filter-sets'"
                [backgroundColorVar]="'background.base'"
                [resizeable]="false"
                [enrichedScroll]="true"
                [columns]="columns"
                [bulkActions]="bulkActions$ | async"
                [rows]="tableRows$ | async"
                [searchQuery]="searchQuery$ | async"
                [state]="filtersetState$ | async"
                [defaultSortByKey]="'created'"
                [defaultSortDirection]="'desc'"
                (onBulkAction)="handleBulkActions($event)"
                (onBulkActionSelectionChange)="bulkActionSelection$.next($event)"
                (onCellAction)="handleCellActions($event)"
            >
            </evc-table>
        </div>

        @if (modalOpen) {
            <filterset-modal
                [(open)]="modalOpen"
                [availableFilters]="allAvailableFilterKeys$ | async"
                [baseFilters]="filtersRepo.baseFilters$ | async"
                [mode]="modalMode"
                [canSwitchType]="modalMode == 'create' ? true : false"
                [editFilterset]="editFilterSet$ | async"
                [editAlert]="editAlert$ | async"
            >
            </filterset-modal>
        }
    `,
    styleUrls: ['./filtersets.component.scss']
})
export class FiltersetsComponent {
    // whether the filter set modal is open or not
    public modalOpen: boolean = false;
    // mode of the filter set modal
    public modalMode: 'create' | 'update' | 'preview' = 'create';
    // selecteable filter variables
    public allAvailableFilterKeys$: Observable<selectFilterOption[]>;
    // collapsible state
    public collapsed$ = new BehaviorSubject<boolean>(true);
    // filter set columns to display
    public columns: TableColumn[] = [];
    // filter set base columns
    public baseColumns = (): TableColumn[] => [
        {
            id: 0,
            title: this._t('FILTER_SETS_VIEW.COLUMNS.NAME'),
            keys: [{
                key: 'name',
                title: this._t('FILTER_SETS_VIEW.COLUMNS.NAME'),
                type: 'string',
            }],
            config: {
                noSearch: true,
                squished: 'left'
            },
            renderCell: () => '',
            actions: [{
                id: 'preview',
                title: this._t('FILTERS.MANAGE.PREVIEW_FILTER_SET', {content: this._t('FILTERS.FILTER_SET.ONE')}),
                renderAction: (attr) => {
                    return `<p class="text-left">${attr}</p>`;
                }
            }]
        },
        {
            id: 2,
            title: this._t('FILTER_SETS_VIEW.COLUMNS.CREATED'),
            keys: [{
                key: 'created',
                title: this._t('FILTER_SETS_VIEW.COLUMNS.CREATED'),
                type: 'date'
            }],
            config: {
                noSearch: true,
                noMinWidth: true,
                defaultWidth: 130,
                squished: 'left'
            },
            renderCell(attr) {
                return `<p class="text-center subline">${formatDate(attr[0], 'dd.MM.y', 'en')}</p>`
            }
        },
        {
            id: 3,
            title: this._t('FILTER_SETS_VIEW.COLUMNS.MODIFIED'),
            keys: [{
                key: 'modified',
                title: this._t('FILTER_SETS_VIEW.COLUMNS.MODIFIED'),
                type: 'date'
            }],
            config: {
                noSearch: true,
                noMinWidth: true,
                defaultWidth: 130,
                squished: 'left'
            },
            renderCell(attr) {
                return `<p class="text-center subline">${formatDate(attr[0], 'dd.MM.y', 'en')}</p>`
            }
        },
        {
            id: 4,
            title: this._t('FILTER_SETS_VIEW.COLUMNS.RESULTS'),
            keys: [{
                key: 'results',
                title: this._t('FILTER_SETS_VIEW.COLUMNS.RESULTS'),
                type: 'number'
            }],
            config: {
                noSearch: true,
                noFilter: true,
                noMinWidth: true,
                defaultWidth: 130,
                squished: 'left'
            },
            renderCell: (attr) => {
                return `<p class="text-right">${attr[0] == null ||
                        attr[0] == undefined
                        ? this._t('COMMON.LOADING.DEFAULT') + '...'
                        : attr[0] == 0
                            ? '-'
                            : attr[0]
                    }</p>`;
            },
        },
        {
            id: 5,
            title: this._t('FILTER_SETS_VIEW.COLUMNS.CONDITION'),
            keys: [
                {
                    key: 'condition',
                    title: this._t('FILTER_SETS_VIEW.COLUMNS.CONDITION'),
                    type: 'string'
                },
                {
                    key: 'value',
                    title: this._t('FILTER_SETS_VIEW.KEYS.CONDITION_VALUE'),
                    type: 'number',
                    hidden: true
                }
            ],
            config: {
                noMinWidth: true,
                defaultWidth: 130,
                squished: 'left'
            },
            renderCell: (attr) => `<p class="text-center">${attr[0] && attr[1] !== undefined ? attr[0] + ' ' + attr[1] : ' - '}</p>`
        },
        {
            id: 6,
            title: this._t('FILTER_SETS_VIEW.COLUMNS.ALERT'),
            keys: [
                {
                    key: 'triggered',
                    title: this._t('FILTER_SETS_VIEW.KEYS.TRIGGERED'),
                    type: 'boolean'
                },
                {
                    key: 'value',
                    title: this._t('FILTER_SETS_VIEW.KEYS.CONDITION_VALUE'),
                    type: 'number',
                    hidden: true
                },
                {
                    key: 'results',
                    title: this._t('FILTER_SETS_VIEW.COLUMNS.RESULTS'),
                    type: 'number',
                    hidden: true
                }
            ],
            config: {
                noMinWidth: true,
                defaultWidth: 100,
                squished: 'left'
            },
            renderCell: (attr) => {
                const [triggered, value, results] = attr;
                if (!triggered && value == undefined) return '<div class="text-center"> - </div>'
                return triggered
                    ? `<div class="text-center">
                        <div class="num-circle ${results == null ? this._t('COMMON.LOADING.DEFAULT') : ''}">
                            <span>${results ?? ''}</span>
                        </div>
                    </div>`
                    : `<div class="text-center">
                        <span class="material-icon state-ok-txt">done</span>
                    </div>`
            }
        },
        {
            id: 9,
            title: '',
            keys: [{
                key: 'ownerId',
                title: 'Owner Id',
                type: 'string',
                hidden: true
            }],
            config: {
                noSearch: true,
                noFilter: true,
                noSort: true,
                defaultWidth: 30,
                noMinWidth: true
            },
            renderCell: () => '',
            actions: [
                {
                    id: 'edit',
                    title: this._t('FILTERS.MANAGE.EDIT_FILTER_SET', { content: this._t('FILTERS.FILTER_SET.ONE') }),
                    icon: 'edit',
                    condition: (attr: any[]) => {
                        return attr[0] && attr[0] == this._appRepo.getCurrentUser()?.uuid;
                    }
                }
            ]
        },
        {
            id: 10,
            title: '',
            config: {
                noSearch: true,
                noFilter: true,
                noSort: true,
                defaultWidth: 30,
                noMinWidth: true,
                shaded: true
            },
            actions: [
                {
                    id: 'copy',
                    title: this._t('COMMON.DUPLICATE'),
                    icon: 'content_copy'
                }
            ]
        }
    ]
    public bulkActions$: Observable<BulkSelectAction[]>;
    // selected row ids for bulk actions, needed for control of disabled state of actions
    public bulkActionSelection$ = new BehaviorSubject<number[]>([]);
    public tableRows$: Observable<Record<string, any>[]>;
    // controls mode of the table
    public typeTable$ = new BehaviorSubject<'default'|'shared'>('default');
    // search input
    public search$ = new BehaviorSubject<string>('');
    // search input value
    public searchQuery$: Observable<string | null>;
    // filter set that should be opened in the modal
    public editFilterSet$ = new BehaviorSubject<FilterSet | SharedFilterSet | null>(null);
    // alert that should be opened in the modal
    public editAlert$: Observable<Alert | null>;
    // controls "Alert only" filter
    public alertOnly$ = new BehaviorSubject<boolean>(false);
    // controls "only results" filter
    public resultsOnly$ = new BehaviorSubject<boolean>(false);
    // whether a filter set is being (un)subscribed or not
    public subscribing$ = new BehaviorSubject<boolean>(false);
    // state for table
    public filtersetState$: Observable<'error' | 'loading' | 'success'> = combineLatest([
            this.filtersRepo.filterSets$, 
            this.subscribing$
        ]).pipe(
        map(([filterSets, subscribing]) => {
            const { status } = filterSets;        
            if (subscribing) return 'loading';
            else return status === 'idle' ? 'success' : status;
        })
    );

    constructor(
        public overviewRepo: overviewRepository,
        public filtersRepo: stationFiltersRepository,
        public notificationService: NotificationService,
        private _appRepo: appRepository,
        private _columnService: ColumnService,
        private _filtersetsService: FiltersetsService,
        private _overviewCacheService: OverviewCacheService,
        private _overviewService: OverviewService,
        private _router: Router,
        private _translate: TranslateService
    ) {
        // control columns of table by mode
        combineLatest({
            type: this.typeTable$,
            langChanged: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            takeUntilDestroyed(),
            tap(({type}) => {
                const $this = this;
                if (type == 'default') {
                    this.columns = [...this.baseColumns(),
                        {
                            id: 8,
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                            keys: [{
                                key: 'filterSetDefinition',
                                title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                                type: 'string'
                            }],
                            config: {
                                noSearch: true,
                                noFilter: true,
                                squished: 'left'
                            },
                            renderCell(attr: FilterSet['filterSetDefinition'][]) {
                                const pipeTransform = new ChargerKeyName($this._translate);
                                const maxElements = 6;
                                let results: any[] = [];
                                attr[0].forEach((def, index: number) => {
                                    if (index < maxElements) {
                                        def.id ? results.push(`<span class="bubble">${pipeTransform.transform(def.id)}</span>`) : '';
                                    }
                                });
                                if (attr[0].length > maxElements) {
                                    const remainingCount = attr[0].length - maxElements;
                                    results.push(`<span class="no-break">+${remainingCount}</span>`);
                                }
                                return `<div class="column-array">${results.join('')}</div>`;
                            },
                        },
                        {
                            id: 11,
                            title: '',
                            config: {
                                noSearch: true,
                                noFilter: true,
                                noSort: true,
                                defaultWidth: 30,
                                noMinWidth: true,
                                shaded: true
                            },
                            actions: [
                                {
                                    id: 'open-new',
                                    title: this._t('COMMON.OPEN_IN_NEW_TAB'),
                                    icon: 'open_in_new'
                                }
                            ]
                        }
                    ];
                } else {
                    this.columns = [...this.baseColumns(), 
                        {
                            id: 1,
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.CREATOR'),
                            keys: [{
                                key: 'ownerName',
                                title: this._t('FILTER_SETS_VIEW.COLUMNS.CREATOR'),
                                type: 'string'
                            }, {
                                key: 'ownerId',
                                title: 'Owner Id',
                                type: 'string',
                                hidden: true
                            }],
                            config: {
                                noMinWidth: true,
                                noSearch: true,
                                squished: 'left'
                            },
                            renderCell: (attr: any[]) => {
                                return this._columnService.getEmailStyling(attr);
                            }
                        },
                        {
                            id: 7,
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.TENANT_SAVED_IN'),
                            keys: [{
                                key: 'tenant',
                                title: this._t('FILTERS.FORM.TENANT'),
                                type: 'string'
                            }],
                            config: {
                                noMinWidth: true,
                                noSearch: true,
                                squished: 'left'
                            }
                        },
                        {
                            id: 8,
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                            keys: [{
                                key: 'filterSetDefinition',
                                title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                                type: 'string'
                            }],
                            config: {
                                noSearch: true,
                                noFilter: true,
                                squished: 'left'
                            },
                            renderCell(attr: FilterSet['filterSetDefinition'][]) {
                                const pipeTransform = new ChargerKeyName($this._translate);
                                const maxElements = 6;
                                let results: any[] = [];
                                attr[0].forEach((def, index: number) => {
                                    if (index < maxElements && def.id !== 'tenant') {
                                        if (def.id) results.push(`<span class="bubble">${pipeTransform.transform(def.id)}</span>`);
                                    }
                                });
                                if (attr[0].length > maxElements) {
                                    const remainingCount = attr[0].length - maxElements;
                                    results.push(`<span class="no-break">+${remainingCount}</span>`);
                                }
                                return `<div class="column-array">${results.join('')}</div>`;
                            },
                        },
                        {
                            id: 11,
                            title: '',
                            keys: [{
                                key: 'isSubscribed',
                                title: this._t('FILTER_SETS_VIEW.KEYS.SUBSCRIBED'),
                                type: 'boolean',
                                hidden: true
                            }],
                            renderCell: (attr) => '',
                            config: {
                                noSearch: true,
                                noFilter: true,
                                noSort: true,
                                defaultWidth: 30,
                                noMinWidth: true,
                                shaded: true
                            },
                            actions: [
                                {
                                    id: 'subscribe',
                                    title: (attr) => {
                                        return attr[0] 
                                            ? this._t('FILTER_SETS_VIEW.KEYS.SUBSCRIBED')
                                            : this._t('FILTER_SETS_VIEW.KEYS.NOT_SUBSCRIBED')
                                    },
                                    icon: (attr) => {
                                        return attr[0] ? 
                                            'check_circle_outline' : 
                                            'radio_button_unchecked'
                                    }
                                }
                            ]
                        }
                    ];
                }
            })
        ).subscribe();

        this.allAvailableFilterKeys$ = this.filtersRepo.filterVariables$.pipe(
            map(({data}) => data.map((filterVar) => ({
                label: filterVar.label,
                value: filterVar.key,
            })))
        )

        // query all filtersets results
        const results$: Observable<{
            filtersetId: number, 
            results: number
        }[]> = this.filtersRepo.filterSets$.pipe(
            filter(({isSuccess}) => isSuccess),
            switchMap(({ data }) => {
                return from(data).pipe(
                    take(data.length),
                    mergeMap((filterset) => {
                        if (!filterset.filterSetDefinition || filterset.filterSetDefinition.length == 0) {
                            return of({filtersetId: filterset.filterSetId, results: 0})
                        }
                        // request stations with current set
                        let filterQuery = filterset.filterSetDefinition.map((filter) => {
                            if (!filter.id || !filter.value || filter.value.length == 0) return null
                            let filterObj: any = {};
                            filterObj[filter.id] = filter.value;
                            return filterObj
                        }).filter(x => x !== null);
                        // we use the locations endpoint, because we dont need the full chargingStation object and we dont want to worry about pagination
                        // @ts-ignore
                        return this._overviewService.getLocationsOfChargingStations({filters: filterQuery}).pipe(
                            catchError(() => EMPTY),
                            map((res) => {
                                return {
                                    filtersetId: filterset.filterSetId,
                                    results: res.length
                                }
                            })
                        )
                    }),
                    toArray()
                )
            }),
            // start with empty to allow values in table before mergeMap above finishes
            startWith([])
        )

        // get all currently cached filtersets, alerts and request num of results
        this.tableRows$ = combineLatest({
            filtersets: this.filtersRepo.filterSets$.pipe(
                filter(({isSuccess}) => isSuccess),
                map(({ data }) => data)
            ),
            results: results$,
            alerts: this.filtersRepo.alerts$.pipe(
                map(({data}) => data)
            ),
            typeTable: this.typeTable$,
            resultsOnlyFilter: this.resultsOnly$,
            alertOnlyFilter: this.alertOnly$,
            langChanged: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            map(({ filtersets, results, alerts, typeTable, resultsOnlyFilter, alertOnlyFilter }) => {
                const uuid = this._appRepo.getCurrentUser()?.uuid;

                const resultsLoading = filtersets.length !== results.length && results.length == 0;

                const operatorMap = {
                    gt: this._t('ALERTS.MORE_THAN'),
                    eq: this._t('ALERTS.EQUALS'),
                    lt: this._t('ALERTS.LESS_THAN')
                }

                let mappedFiltersets = filtersets.map((filterset) => {
                    const filterRes = results.find((result) => result.filtersetId == filterset.filterSetId);
                    const filterAlert = alerts.find((alert) => alert.filterSetId == filterset.filterSetId);
                    return Object.assign({}, filterset, {
                        results:            resultsLoading ? null : filterRes?.results,
                        condition:          filterAlert && filterAlert.operator ? operatorMap[filterAlert.operator] : undefined,
                        value:              filterAlert?.value ?? undefined,
                        triggered:          filterAlert?.triggered ?? undefined,
                        bulkActionDisabled: uuid != filterset.ownerId
                    })
                })

                if (typeTable == 'default') {
                    mappedFiltersets = mappedFiltersets.filter((set) => !set.isShared)
                }

                if (typeTable == 'shared') {
                    mappedFiltersets = mappedFiltersets.filter((set) => set.isShared)
                }

                if (alertOnlyFilter) {
                    mappedFiltersets = mappedFiltersets.filter((set) => set.condition !== undefined);
                }

                if (resultsOnlyFilter) {
                    mappedFiltersets = mappedFiltersets.filter((set) => set.results && (set.results as number) > 0);
                }

                return mappedFiltersets
            })
        )

        // finds associated alert to filter set that should be updated
        this.editAlert$ = this.editFilterSet$.pipe(
            withLatestFrom(this.filtersRepo.alerts$.pipe(
                map(({ data }) => data)
            )),
            map(([filterSet, allAlerts]) => {
                const match = filterSet && allAlerts.find((alert) => alert.filterSetId === filterSet.filterSetId);
                return match || null
            })
        )

        // throttle search input
        this.searchQuery$ = this.search$.pipe(
            skip(1),
            debounceTime(150),
            distinctUntilChanged(),
        );

        this.bulkActions$ = combineLatest({
            tableRows: this.tableRows$,
            selectedRows: this.bulkActionSelection$,
            currentUser: this._appRepo.currentUser$,
            langChanged: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            map(({tableRows, selectedRows, currentUser}) => {
                const selectedTableRows = selectedRows.map((index) => tableRows[index]);
                const selectionHasAlerts = selectedTableRows.some((row) => row['condition'] !== undefined);
                const userIsOwner = selectedTableRows.every((row) => row['ownerId'] === currentUser?.uuid);

                return [
                    {
                        id: 'deleteFilterSets',
                        title: this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_FILTER_SETS.TITLE'),
                        icon: 'delete',
                        confirmation: {
                            title: this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_FILTER_SETS.CONFIRMATION.TITLE'),
                            text: (selectedIds) => selectedIds.length == 1 
                                ? this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_FILTER_SETS.CONFIRMATION.TEXT.ONE', {n: selectedIds.length})
                                : this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_FILTER_SETS.CONFIRMATION.TEXT.OTHER', {n: selectedIds.length}),
                            button: {
                                text: this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_FILTER_SETS.CONFIRMATION.BUTTON'),
                                icon: 'delete'
                            }
                        },
                        conditions: {
                            disabled: !userIsOwner
                        }
                    },
                    {
                        id: 'deleteAlerts',
                        title: this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_ALERTS.TITLE'),
                        icon: 'delete',
                        confirmation: {
                            title: this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_ALERTS.CONFIRMATION.TITLE'),
                            text: (selectedIds) => selectedIds.length == 1 
                                ? this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_ALERTS.CONFIRMATION.TEXT.ONE', {n: selectedIds.length})
                                : this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_ALERTS.CONFIRMATION.TEXT.OTHER', {n: selectedIds.length}),
                            button: {
                                text: this._t('FILTER_SETS_VIEW.BULK_ACTIONS.DELETE_ALERTS.CONFIRMATION.BUTTON'),
                                icon: 'delete'
                            }
                        },
                        conditions: {
                            disabled: !selectionHasAlerts
                        }
                    }
                ]
            })
        );
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    handleSearch(event: Event) {
        let value = (event.target as HTMLInputElement).value;
        this.search$.next(value);
    }

    public handleCellActions(cellAction: {actionId: string, row: number}) {
        combineLatest([
            this.filtersRepo.filterSets$,
            this.tableRows$
        ]).pipe(
            take(1),
            map(([{data}, rows]) => {
                const row = rows[cellAction.row];
                const entry = data.find(d => d.filterSetId == row["filterSetId"]);
                if (!entry) return;
                if (cellAction.actionId == 'edit') {
                    this.modalOpen = true;
                    this.modalMode = 'update';
                    entry.name = decodeString(entry.name);
                    this.editFilterSet$.next(entry);
                }
                if (cellAction.actionId == 'copy') {
                    this.modalOpen = true;
                    this.modalMode = 'create';
                    const copy = structuredClone(entry);
                    copy.name = decodeString(copy.name);
                    copy.name = copy.name+'_copy';
                    this.editFilterSet$.next(copy);
                }
                if (cellAction.actionId == 'preview') {
                    this.modalOpen = true;
                    this.modalMode = 'preview';
                    entry.name = decodeString(entry.name);
                    this.editFilterSet$.next(entry);
                }
                if (cellAction.actionId == 'subscribe') {
                    if (!('isSubscribed' in entry)) return;
                    this.subscribing$.next(true);
                    this._filtersetsService.subscribeFilterSet({
                        filtersetid: entry.filterSetId,
                        body: {subscribe: !entry.isSubscribed}
                      }).pipe(
                        take(1),
                        tap(_ => {
                            const text = this._t(!entry.isSubscribed ? 'FILTER_SETS_VIEW.INFO.SUBSCRIBED' : 'FILTER_SETS_VIEW.INFO.UNSUBSCRIBED')
                            this.notificationService.showSuccess(text);
                            this._overviewCacheService.updateFilterSetsCache();
                            this._overviewCacheService.updateAlertsCache();
                            this.subscribing$.next(false);
                        }),
                        catchError(_ => {
                            const text = this._t(!entry.isSubscribed ? 'FILTER_SETS_VIEW.INFO.ERROR_SUBSCRIBED' : 'FILTER_SETS_VIEW.INFO.ERROR_UNSUBSCRIBED')
                            this.notificationService.showError(text);
                            this.subscribing$.next(false);
                            return of([]);
                        })
                    ).subscribe();
                }
                if (cellAction.actionId == 'open-new') {
                    // apply filterset
                    this.filtersRepo.deleteAllFilters()
                    // apply id and value of each filter in set to repo
                    entry.filterSetDefinition.forEach(({id, value}) => {
                        if (id) this.filtersRepo.addFilter(id, value);
                    })
                    // open overview in new tab
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/overview`])
                    );
                    window.open(url, '_blank')   
                }
            })
        ).subscribe()
    }

    public handleBulkActions(bulkaction: {action: string, rows: number[]}) {
        combineLatest({
            filterSets: this.filtersRepo.filterSets$,
            rows: this.tableRows$,
            alerts: this.filtersRepo.alerts$.pipe(
                map(({data}) => data)
            )
        }).pipe(
            take(1),
            switchMap(({filterSets, rows, alerts}) => {
                const { data } = filterSets;
                const uuid = this._appRepo.getCurrentUser()?.uuid;
                // filters that should be deleted
                let matchingRows = bulkaction.rows.map((index) => rows[index]),
                    matchingFilterSets = data.filter(d => {
                        if (matchingRows.map(row => row["filterSetId"]).includes(d.filterSetId)) return d.ownerId === uuid;
                        return false;
                    }),
                    matchingFilterSetsIds = matchingFilterSets.map((set) => set.filterSetId),
                    // alerts of to-be-deleted filter sets
                    matchingAlerts: Alert[] = alerts.filter((alert) => matchingFilterSetsIds.includes(alert.filterSetId));

                const showEmptyMessage = () => {
                    this.notificationService.showInfo(this._t('FILTER_SETS_VIEW.INFO.NOTHING_TO_DELETE'))
                    return EMPTY
                };
                
                if (bulkaction.action == 'deleteFilterSets') {
                    if (!matchingFilterSets.length) return showEmptyMessage();
                    // delete filterSets
                    return from(matchingFilterSets).pipe(
                        switchMap((filterset) => 
                            this._filtersetsService.deleteFilterSet({
                                filtersetid: filterset.filterSetId
                            }).pipe(
                                catchError((err) => {
                                    this.notificationService.showError(this._t('FILTER_SETS_VIEW.INFO.UNABLE_TO_DELETE'), this._t('COMMON.ERROR.ONE'))
                                    return EMPTY
                                }),
                            )
                        ),
                        tap(() => {
                            // once all requests were successful, notify user and update cache
                            this.notificationService.showSuccess(
                                this._t(matchingFilterSets.length == 1
                                    ? 'FILTER_SETS_VIEW.INFO.FILTER_SET_DELETED.ONE'
                                    : 'FILTER_SETS_VIEW.INFO.FILTER_SET_DELETED.OTHER', {
                                    n: matchingFilterSets.length
                                })
                            );
                            this._overviewCacheService.updateFilterSetsCache();
                            this._overviewCacheService.updateAlertsCache();
                        })
                    )
                } else {
                    if (!matchingAlerts.length) return showEmptyMessage();
                    // only delete alerts
                    return from(matchingAlerts).pipe(
                        switchMap((alert) =>
                            this._filtersetsService.deleteAlert({
                                alertId: alert.alertId
                            }).pipe(
                                catchError((err) => {
                                    this.notificationService.showError(this._t('FILTER_SETS_VIEW.INFO.UNABLE_TO_DELETE_ALERT'), this._t('COMMON.ERROR.ONE'))
                                    return EMPTY
                                })
                            )
                        ),
                        tap(() => {
                            // once all requests were successful, notify user and update cache
                            this.notificationService.showSuccess(
                                this._t(matchingAlerts.length == 1
                                    ? 'FILTER_SETS_VIEW.INFO.ALERT_DELETED.ONE'
                                    : 'FILTER_SETS_VIEW.INFO.ALERT_DELETED.OTHER', {
                                    n: matchingAlerts.length
                                })
                            );
                            this._overviewCacheService.updateAlertsCache();
                        })
                    )
                }
            })
        ).subscribe()
    }
}
