/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataApiConfiguration } from './data-api-configuration';

import { OverviewService } from './services/overview.service';
import { DetailsMapService } from './services/details-map.service';
import { DetailsOverviewService } from './services/details-overview.service';
import { KpiService } from './services/kpi.service';
import { DetailsHealthService } from './services/details-health.service';
import { DetailsErrorsService } from './services/details-errors.service';
import { DetailsChargingService } from './services/details-charging.service';
import { DetailsConnectivityService } from './services/details-connectivity.service';
import { DetailsTimelineService } from './services/details-timeline.service';
import { DetailsRestartService } from './services/details-restart.service';
import { ConfigurationService } from './services/configuration.service';
import { HealthService } from './services/health.service';
import { AdminService } from './services/admin.service';
import { UserService } from './services/user.service';
import { TrafficLoggerService } from './services/traffic-logger.service';
import { DetailsTicketsService } from './services/details-tickets.service';
import { FiltersetsService } from './data-services';
import { TenantService } from './data-services';
import { NotificationDataService } from './data-services';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    OverviewService,
    DetailsMapService,
    DetailsOverviewService,
    KpiService,
    DetailsHealthService,
    DetailsErrorsService,
    DetailsChargingService,
    DetailsConnectivityService,
    DetailsTimelineService,
    DetailsRestartService,
    ConfigurationService,
    HealthService,
    AdminService,
    UserService,
    TrafficLoggerService,
    DataApiConfiguration,
    DetailsTicketsService,
    FiltersetsService,
    TenantService,
    NotificationDataService
  ],
})
export class DataApiModule {
  static forRoot(): ModuleWithProviders<DataApiModule> {
    return {
      ngModule: DataApiModule,
      providers: [
        {
          provide: DataApiConfiguration
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: DataApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('DataApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
