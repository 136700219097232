import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { DetailsComponent } from './details/details.component';
import { MapViewComponent } from './map-view/map-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { KpiViewComponent } from './kpi-view/kpi-view.component';
import { AdminSelectionComponent } from './shared/admin-selection/admin-selection.component';
import { FiltersetsComponent } from './filtersets/filtersets.component';
import { userRoutingGuard } from './core/guards/userRouting.guard';
import { NotificationsViewComponent } from './notifications-view/notifications-view.component';
import { InsightsComponent } from './insights/insights.component';
import { rootRoutingGuard } from './core/guards/rootRouting.guard';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
    { path: 'overview', component: OverviewComponent, canActivate: [userRoutingGuard] },
    { path: 'map', component: MapViewComponent, canActivate: [userRoutingGuard] },
    { path: 'kpi', component: KpiViewComponent, canActivate: [userRoutingGuard] },
    { path: 'notifications', component: NotificationsViewComponent, canActivate: [userRoutingGuard] },
    { path: 'admin-selection', component: AdminSelectionComponent, canActivate: [userRoutingGuard] },
    { path: 'filtersets', component: FiltersetsComponent, canActivate: [userRoutingGuard] },
    { path: 'details/:id', component: DetailsComponent, loadChildren: () => import('./details/details.module').then(m => m.DetailsModule), canActivate: [userRoutingGuard] },
    { path: 'insights', component: InsightsComponent, loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule), canActivate: [userRoutingGuard] },
    { path: 'admin', component: AdminComponent, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [userRoutingGuard]},
    // handle deprecated routes
    { path: 'admin-notifications', redirectTo: 'notifications'},
    { path: 'admin-users', redirectTo: 'admin/manage-users' },
    { path: 'admin-tenants', redirectTo: 'admin/manage-tenants' },
    // using rootRoutingGuard to redirect user to proper view after login
    { path: '', component: OverviewComponent, canActivate: [rootRoutingGuard] },
    // wildcard for 404
    { path: '**', component: NotFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
